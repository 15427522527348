import { ReactNode } from 'react'
import './tk.css'

type Children = {
  children: ReactNode
}

const TKVariables = ({ children }: Children) => <>{children}</>

export default TKVariables
